import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LuceneValidatorService {

  constructor() {}

  // Metodo pubblico per validare una query
  public validateQuery(query: string): boolean {
    if (!query || query.trim().length === 0) {
      return false;
    }

    query = this.removeEscapes(query);

    // Validazione sequenziale
    return (
      this.checkAllowedCharacters(query) &&
      this.checkAsterisk(query) &&
      this.checkAmpersands(query) &&
      this.checkCaret(query) &&
      this.checkSquiggle(query) &&
      this.checkExclamationMark(query) &&
      this.checkQuestionMark(query) &&
      this.checkParentheses(query) &&
    //   this.checkPlusMinus(query) &&
      this.checkANDORNOT(query) &&
      this.checkQuotes(query) &&
      this.checkColon(query)
    );
  }

  // Rimuove caratteri di escape
  private removeEscapes(query: string): string {
    return query.replace(/\\./g, '');
  }

  // Controlla caratteri ammessi
  private checkAllowedCharacters(query: string): boolean {
    const matches = query.match(/[^a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'= ]/);
    if (matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso del carattere asterisco (*)
  private checkAsterisk(query: string): boolean {
    const matches = query.match(/^[\*]*$|[\s]\*|^\*[^\s]/);
    if (matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso di &&
  private checkAmpersands(query: string): boolean {
    const matches = query.match(/[&]{2}/);
    if (matches) {
      if (!query.match(/^([a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]+( && )?[a-zA-Z0-9_+\-:.()"*?|!{}\[\]\^~\\@#/$%'=]+[ ]*)+$/)) {
        return false;
      }
    }
    return true;
  }

  // Controlla l'uso del caret (^)
  private checkCaret(query: string): boolean {
    const matches = query.match(/[^\\]\^([^\s]*[^0-9.]+)|[^\\]\^$/);
    if (matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso del tilde (~)
  private checkSquiggle(query: string): boolean {
    const matches = query.match(/[^\\]~[^\s]*[^0-9\s]+/);
    if (matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso del punto esclamativo (!)
  private checkExclamationMark(query: string): boolean {
    const matches = query.match(/^[^!]*$|^([a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]+( ! )?[a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]+[ ]*)+$/);
    if (!matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso del punto interrogativo (?)
  private checkQuestionMark(query: string): boolean {
    const matches = query.match(/^(\?)|([^a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]\?+)/);
    if (matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso delle parentesi
  private checkParentheses(query: string): boolean {
    const leftMatch = query.match(/[(]/g);
    const rightMatch = query.match(/[)]/g);

    if ((leftMatch && !rightMatch) || (!leftMatch && rightMatch)) {
      return false;
    }

    if (leftMatch && rightMatch && leftMatch.length !== rightMatch.length) {
      return false;
    }

    return true;
  }

  // Controlla l'uso di + e -
  private checkPlusMinus(query: string): boolean {
    const matches = query.match(/^[^\n+\-]*$|^([+-]?[a-zA-Z0-9_:.()"*?&|!{}\[\]\^~\\@#/$%'=]+[ ]?)+$/);
    if (!matches) {
      return false;
    }
    return true;
  }

  // Controlla l'uso di AND, OR, NOT
  private checkANDORNOT(query: string): boolean {
    const matches = query.match(/AND|OR|NOT/);
    if (matches && !query.match(/^([a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]+\s*((AND )|(OR )|(AND NOT )|(NOT ))?[a-zA-Z0-9_+\-:.()"*?&|!{}\[\]\^~\\@#/$%'=]+[ ]*)+$/)) {
      return false;
    }
    return true;
  }

  // Controlla l'uso delle virgolette
  private checkQuotes(query: string): boolean {
    const matches = query.match(/\"/g);
    if (matches && matches.length % 2 !== 0) {
      return false;
    }
    return true;
  }

  // Controlla l'uso dei due punti (:)
  private checkColon(query: string): boolean {
    const matches = query.match(/[^\\\s]:[\s]|[^\\\s]:$|[\s][^\\]?:|^[^\\\s]?:/);
    if (matches) {
      return false;
    }
    return true;
  }
}
